import React from 'react';
import styles from './index.module.scss';

// componentes
import Horario from './Horario';

// dia
export default function Dia({
    dados
}){

    return <div
    className={styles.dia}
>
    
    
    <div
        className={styles.diaLabel}
    >
        <div
            className={styles.labelIcone}
        >
            {/* <span className={styles.linhaHorizontal}></span> */}
            <span className={styles.linha}></span>
            <div className={styles.circulo}>
                <span>{dados.dia}</span>
            </div>
        </div>
        <div
            className={styles.labelDescricao}
        >
            
        </div>
    </div>
    
    {dados.horas.map(hora => 
        <Horario 
            key={hora.hora}
            dados={hora}
        />
    )}
    

</div>

}