import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import stylesGeral from './../index.module.scss';
import axios from 'axios';

// componentes
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Card, Row, Col, Form, Button, InputGroup, Tabs, Tab} from 'react-bootstrap';
import InputDataCombo from './../../../../Componentes/InputDataCombo';

import {copiaCampo} from './../../../../Funcoes';

// retorna conteudo
export default function FormularioPadrao({
    dados={},
    alterarDados,
    funcaoSalvar,
    salvando,
    modo,
    edicaoLiberada
}){

    const history = useHistory();
    const permissaoReducer = useSelector(state => state.permissaoReducer);

    const fichaLiberada = typeof edicaoLiberada !== "undefined" ? edicaoLiberada : permissaoReducer.edicaoClientes === 'S';
    const [ mostrarCnpjTitularidade, alterarMostrarCnpjTitularidade ] = useState(false);
    
    // estados
    
    // campos para referencia
    const camposRef = {
        razaoSocial: React.useRef(),
    }
  
    // array para o select de modo
    const modosCliente = [
        {value: 'cliente', label: 'Cliente'},
        {value: 'clientelight', label: 'Clientelight'},
        {value: 'parceiro', label: 'Parceiro'},
        {value: 'demo', label: 'Demo'},
        {value: 'demolight', label: 'Demolight'},
        {value: 'bloqueado', label: 'Bloqueado'},
        {value: 'cancelado', label: 'Cancelado'},
        {value: 'nao-ativo', label: 'Não ativo'}
    ]

    // prepara a situação
    const situacaoCliente = [
        {value: 'demo', label: 'Demo'},
        {value: 'cliente', label: 'Cliente'},
        {value: 'parceiro', label: 'Parceiro'},
        {value: 'bloqueado', label: 'Bloqueado'},
        {value: 'cancelado', label: 'Cancelado'}
    ];

    const planosSistema = [
        {value: 'padrao', label: 'Padrão'},
        {value: 'light', label: 'Light'}
    ];

    // pega o modo inicial se edição
    const situacaoInicial = (dados.id) ? situacaoCliente.filter((e) => dados.situacao === e.value) : null

    return <>
        <Form.Row className='mb-2'>
            <Col md='2'>
                <Form.Label>Código</Form.Label>
                <Form.Control 
                    size="sm"
                    disabled
                    value={dados.codigo || ''}
                />
            </Col>
            <Col md='2'>
                <Form.Label>ID</Form.Label>
                <Form.Control 
                    size="sm"
                    disabled
                    value={dados.id || ''}
                />
            </Col>
            <Col>
                <Form.Label>Razão social</Form.Label>
                <InputGroup className="mb-0">
                    <Form.Control 
                        size="sm"
                        disabled={!fichaLiberada}
                        value={dados.razaoSocial || ''}
                        ref={camposRef.razaoSocial}
                        onChange={e => {
                            alterarDados({...dados, razaoSocial: e.target.value});
                        }}
                    />
                    <InputGroup.Append>
                        <Button 
                            size="sm"
                            className={[stylesGeral.btnFormularios, ''].join(' ')}
                            variant="outline-secondary"
                            onClick={() => {

                                // pega o campo
                                let campo = camposRef.razaoSocial.current
                                copiaCampo(campo);

                            }}
                        >
                            <FontAwesomeIcon style={{fontSize: 14}} className="icone" icon={["fas", "copy"]}/>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
        </Form.Row>
        <Form.Row className='mb-2'>
            <Col md='3'>
                <div className={'d-flex justify-content-between'}>
                    <Form.Label>Situação</Form.Label>
                </div>
                <Select
                    className="select-ficha"
                    isDisabled={!fichaLiberada}
                    options={situacaoCliente}
                    defaultValue={situacaoInicial[0]}
                    isMulti={false}
                    placeholder='Situação'
                    onChange={(e) => {
                        // altera os filtros selecionados
                        alterarDados({...dados, situacao: e.value});
                    }}
                />
            </Col>
            <Col md='3'>
                <div className={'d-flex justify-content-between'}>
                    <Form.Label>Plano</Form.Label>
                </div>
                <Select
                    className="select-ficha"
                    isDisabled={!fichaLiberada}
                    options={planosSistema}
                    defaultValue={dados.planoSistema ? planosSistema.find((p) => p.value === dados.planoSistema) : null}
                    isMulti={false}
                    placeholder='Plano'
                    onChange={(e) => {
                        // altera os filtros selecionados
                        alterarDados({...dados, planoSistema: e.value});
                    }}
                />
            </Col>
            <Col md='4'>
                <div className={'d-flex justify-content-between'}>
                    <Form.Label>
                        {
                            dados.estrangeiro === 'S' ? `Documento` : (
                                dados.pessoaFisica === 'S'
                            ) ? `CPF` : `CNPJ`
                        }
                    </Form.Label>
                    {
                        (dados.estrangeiro === 'N' && dados.pessoaFisica === 'N') && <Form.Check
                            // className="check-mostrar-cnpj-titularidade"
                            id={"switch-mostrar-cnpj-titularidade"}
                            label="Mostrar CNPJs"
                            checked={mostrarCnpjTitularidade}
                            onChange={e => {
                                alterarMostrarCnpjTitularidade(e.target.checked);
                            }}
                        />
                    }
                </div>
                {
                    (dados.estrangeiro === 'S' || dados.pessoaFisica === 'S') ? <Form.Control 
                        size="sm"
                        disabled={!fichaLiberada}
                        value={dados.numeroDocumento || ''}
                        onChange={(e) => {
                            // altera os filtros selecionados
                            alterarDados({...dados, numeroDocumento: e.target.value});
                        }}
                    /> : <Form.Control 
                            size="sm"
                            disabled={!fichaLiberada}
                            value={dados.cnpj || ''}
                            onChange={(e) => {
                                // altera os filtros selecionados
                                alterarDados({...dados, cnpj: e.target.value});
                            }}
                    />
                }
            </Col>
            <Col md='2' className={'d-flex flex-column justify-content-end'}>
                <Form.Check
                    id={fichaLiberada ? "switch-estrangeiro" : "switch-estrangeiro-desabilitado"}
                    label="Estrangeiro"
                    disabled={!fichaLiberada || dados.pessoaFisica === 'S'}
                    checked={dados.estrangeiro === 'S'}
                    onChange={e => {
                        if(!fichaLiberada) return false;
                        alterarDados({...dados, estrangeiro: e.target.checked ? 'S' : 'N'});
                    }}
                />
                <Form.Check
                    id={fichaLiberada ? "switch-pessoa-fisica" : "switch-pessoa-fisica-desabilitado"}
                    label="Pessoa física"
                    disabled={!fichaLiberada || dados.estrangeiro === 'S'}
                    checked={dados.pessoaFisica === 'S'}
                    onChange={e => {
                        if(!fichaLiberada) return false;
                        alterarDados({...dados, pessoaFisica: e.target.checked ? 'S' : 'N'});
                    }}
                />
            </Col>
        </Form.Row>
        {
            (mostrarCnpjTitularidade && (dados.estrangeiro === 'N' && dados.pessoaFisica === 'N')) && <Form.Row>
                <Col className='mt-4'>
                    <Form.Label className="label-cnpj-titularidade-anterior">
                        CNPJ Titularidade anteriores
                    </Form.Label>
                    <Form.Control 
                        size="sm"
                        disabled={!fichaLiberada}
                        value={dados.cnpjTitularidadeAnterior || ''}
                        onChange={(e) => {
                            // altera os filtros selecionados
                            alterarDados({...dados, cnpjTitularidadeAnterior: e.target.value});
                        }}
                    />
                </Col>
            </Form.Row>
        }
    </>
}