import React from 'react';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function BotaoMenu({
    descricao,
    onMouseDown,
    icone
}){

    return <OverlayTrigger
        placement={'bottom'}
        overlay={
            <Tooltip>{descricao}</Tooltip>
        }
    >
    
        <Button
            size='sm'
            variant='padrao'
            className='mr-1'
            onMouseDown={e => {
                e.preventDefault();
                onMouseDown(e);
            }}
        >
            <FontAwesomeIcon className="icone" icon={icone} />
        </Button>
    </OverlayTrigger>
}

export default BotaoMenu;