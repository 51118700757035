import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';

// componentes
import { Card, Row, Col } from 'react-bootstrap';
import Dia from './Dia';

// histórico
export default function Mes({
    dados
}){


    return <>

        <div
            className={styles.mes}
        >

            <div className={styles.mesLabel}>
                <div
                    className={styles.labelIcone}
                >
                    <span className={styles.linhaHorizontal}></span>
                    <span className={styles.linha}></span>
                    <span className={styles.circulo}></span>
                </div>
                <div className={styles.labelDescricao}>
                    <div>{moment().month(parseInt(dados.mes) - 1).format("MMMM")}</div>
                </div>
            </div>

            {dados.dias.map(dia =>
                <Dia 
                    key={dia.dia}
                    dados={dia}
                />
            )}
        </div>

    </>

}