import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import queryString  from 'query-string';

// componentes
import { Card, Table, Button, Badge, InputGroup, FormControl, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Registro from './Registro';
import Paginacao from './../../../Componentes/Paginacao';
import ParametrosConsulta from './ParametrosConsulta';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import ModalExcluir from './ModalExcluir';

// contextos    
import { PainelContexto } from './../../PainelContexto';
import { ClientesIcone } from './../../../../icones/svg';
import Legenda from './Legenda';
import { intersectObject } from '../../../Funcoes';
import Confirmar from '../../../Componentes/Confirmar';

export default function Consultar(){

    // estados
    let painelContextoDados = useContext(PainelContexto);
    let { permissaoReducer } = useSelector(state => state);
    let history = useHistory();
    let { path, url } = useRouteMatch();
    let location = useLocation();
    let [carregando, alterarCarregando] = useState(false);
    let [totalRegistros, atualizarTotalRegistros] = useState(0);
    const parametrosPadrao = {
        pagina: 1,
        registrosPorPagina: isMobile ? 3 : 10,
        pesquisa: '',
        modo: [], // filtro por situação + plano do sistema
        dataClienteDataInicio: null,
        dataClienteDataFim: null,
        dataBloqueadoDataInicio: null,
        dataBloqueadoDataFim: null,
        dataCadastroDataInicio: null,
        dataCadastroDataFim: null,
        dataCanceladoDataInicio: null,
        dataCanceladoDataFim: null,
        servidorProprio: [],
        idServidorAluguel: null,
        possuiIntegracao: [],
        cavaloTroia: [],
        possuiTokenGoogleCliente: [],
        usarTokenGoogleCliente: [],
        idsVersoes: [],
        idsIgnorar: [],
        idsGruposChaveGoogle: [],
        idsBancosDados: [],
        expirada: [],
        precisaReajuste: null,
        possuiFaturasEmAtraso: null,
        periodoAtivacao: null,
        estrangeiro: []
    };
    let [parametros, alterarParametros] = useState(Object.assign({
        ...parametrosPadrao
    }, queryString.parse(location.search, {arrayFormat: 'bracket'})));
    
    let [consulta, alterarConsulta] = useState([]);
    let [mostrarFiltros, alterarMostrarFiltros] = useState(false);
    let [habilitarIgnorarIds, alterarHabilitarIgnorarIds] = useState(false);
    let [clientesIgnorados, alterarClientesIgnorados] = useState([]);
    const [ primeiraConsulta, alterarPrimeiraConsulta ] = useState(true);
    const [parametrosAtual, alterarParametrosAtual] = useState(null);
    const [ confirmar, alterarConfirmar ] = useState(null);
    const [aplicandoVersao, alterarAplicandoVersao] = useState(false);
    const [liberarAtualizarServidor, alterarLiberarAtualizarServidor] = useState('N');

    // inicializa
    useEffect(() => {

        // codifica a query para salvar no navegador
        let encodeQuery = queryString.stringify(parametros, {arrayFormat: 'bracket'});

        // atualizar url
        history.replace({
            pathname: path,
            search: "?"+encodeQuery
        });
        
        // faz a consulta
        fazerConsulta();

    }, [parametros]);

    useEffect(() => {

        // verifica se já consultado
        if(!primeiraConsulta){
            parametros.idsIgnorar = clientesIgnorados.map(c => c.dados.id);
            alterarParametros({...parametros});
        }
    }, [clientesIgnorados]);

    // quando um usuário muda o status online ou offline, atualiza
    useEffect(() => {

        // recebe um sinal de que algum usuário está online ou offline
        painelContextoDados.socket.current.on('usuariosEmpresaOnline', atualizarUsuariosOnline);

        return () => {
            painelContextoDados.socket.current.off('usuariosEmpresaOnline', atualizarUsuariosOnline);
        }

    }, [consulta]);

    // atualiza quantidade de usuários online
    function atualizarUsuariosOnline(quemFoi){
        
        // atualiza
        let novaConsulta = consulta.map(clienteDados => {
            if(parseInt(quemFoi.id) === parseInt(clienteDados.dados.id)){
                clienteDados.online = quemFoi.dados.online;
            }
            return clienteDados;
        });
        
        // atualiza consulta
        alterarConsulta(novaConsulta);
    }

    // ajusta parametros para pesquisa
    function ajustarParametrosConsulta(){

        let parametrosConsulta = {...parametros};
        let parametrosPesquisa = intersectObject(parametrosPadrao, parametrosConsulta);

        // verifica
        let filtroClientes = ['cliente', 'clientelight', 'parceiro', 'bloqueado', 'cancelado'].some((modo) => parametrosConsulta.modo.includes(modo));
        let empresasLight = ['demolight', 'clientelight'].some((modo) => parametrosConsulta.modo.includes(modo));
        let empresasAconcagua = ['demo', 'cliente', 'parceiro', 'bloqueado', 'cancelado'].some((modo) => parametrosConsulta.modo.includes(modo));
        
        // filtros específicos
        parametrosPesquisa.semFatura = filtroClientes ? parametrosConsulta.semFatura ?? null : null;
        parametrosPesquisa.possuiFaturasEmAtraso = filtroClientes ? parametrosConsulta.possuiFaturasEmAtraso ?? null : null;
        parametrosPesquisa.precisaReajuste = filtroClientes ? parametrosConsulta.precisaReajuste ?? null : null;
        parametrosPesquisa.periodoAtivacao = ['demo', 'demolight'].some((modo) => parametrosConsulta.modo.includes(modo)) ? (parametrosConsulta.periodoAtivacao ?? null) : null;
        
        // filtro por empresas ativas (em teste - demos)
        parametrosPesquisa.expirada = ['demo', 'demolight'].some((modo) => parametrosConsulta.modo.includes(modo)) ? (parametrosConsulta.emTeste === 'S' ? ['N'] : null) : null;

        // verifica parametros de token, se não for modo light
        parametrosPesquisa.possuiTokenGoogleCliente = empresasAconcagua ? parametrosConsulta.possuiTokenGoogleCliente : [];
        parametrosPesquisa.usarTokenGoogleCliente = parametrosPesquisa.possuiTokenGoogleCliente.includes('S') ? parametrosConsulta.usarTokenGoogleCliente : [];

        // verifica filtros de servidores
        parametrosPesquisa.servidorProprio = filtroClientes ? parametrosConsulta.servidorProprio : [];
        parametrosPesquisa.idServidorAluguel = parametrosPesquisa.servidorProprio.includes('N') ? parametrosConsulta.idServidorAluguel || null : null;
        parametrosPesquisa.cavaloTroia = parametrosPesquisa.servidorProprio.includes('N') ? parametrosConsulta.cavaloTroia : [];

        // integracao
        parametrosPesquisa.possuiIntegracao = filtroClientes ? parametrosConsulta.possuiIntegracao : [];
        parametrosPesquisa.idIntegracaoSistema = parametrosPesquisa.possuiIntegracao.includes('S') ? parametrosConsulta.idIntegracaoSistema || null : null;

        return {
            ...parametrosPesquisa,
            codigo: parametrosConsulta.filtroCodigoEmpresa === 'S' ? parametrosConsulta.pesquisa : null,
            telefone: parametrosConsulta.filtroPesquisarTelefone === 'S' ? parametrosConsulta.pesquisa : null,
            cnpj: parametrosConsulta.filtroPesquisarCnpj === 'S' ? parametrosConsulta.pesquisa : null,
            pesquisa: (
                [
                    parametrosConsulta.filtroCodigoEmpresa,
                    parametrosConsulta.filtroPesquisarTelefone,
                    parametrosConsulta.filtroPesquisarCnpj
                ].includes('S') ? null : parametrosConsulta.pesquisa
            ),
            idsServidorAluguel: parametrosPesquisa.idServidorAluguel ? [parametrosPesquisa.idServidorAluguel] : null,
            idsIntegracaoSistema: parametrosPesquisa.idIntegracaoSistema ? [parametrosPesquisa.idIntegracaoSistema] : null
        };

    }

    // faz consulta
    async function fazerConsulta(){

        // validação para evitar varias requisições no servidor
        if(parametrosAtual === JSON.stringify(parametros)) return;
        alterarParametrosAtual(JSON.stringify(parametros));

        // carregando
        alterarCarregando(true);
        alterarPrimeiraConsulta(false);

        // faz a requisição
        try{

            // faz a consulta de empresas
            let { data } = await axios.get('/empresas', {
                params: ajustarParametrosConsulta()
            });

            // finalizado
            alterarConsulta(data.registros);
            atualizarTotalRegistros(data.totalRegistros)
            alterarLiberarAtualizarServidor(data.liberarAtualizarServidor);

        }catch(e){

        }finally{
            alterarCarregando(false);
        }
    }
    
    // retorna registro
    let retornaRegistros = registro => {

        // retorna registros organizado
        return consulta.map(registro => 
            <Registro 
                key={registro.dados.id}
                dados={registro.dados}
                informacoes={registro.informacoes || null}
                online={registro.online}
                mostrarOpcaoAbrirServidor={permissaoReducer.acessoServidorClientes === 'S'}
                clicouAbrirFichaServidor={() => {
                    history.push(`${path}/editar/${registro.dados.id}/servidor`);
                }}
                clicouEditar={() => {
                    history.push(`${path}/editar/${registro.dados.id}`);
                }}
                clicouEnviarEmail={() => {
                    history.push(`${path}/${registro.dados.id}/enviar-email`);
                }}
                habilitarIgnorarIds={habilitarIgnorarIds}
                clicouIgnorarCliente={() => {
                    alterarClientesIgnorados([...clientesIgnorados, registro]);
                }}
            />
        )
    }

    // const opcoesVersao = () => {
    //     return <Popover id="popover-basic">
    //         <Popover.Header as="h3">Popover right</Popover.Header>
    //         <Popover.Body>
    //             texto aqui
    //         </Popover.Body>
    //     </Popover>
        
    // };

    async function limparVersoes(){

        alterarAplicandoVersao(true);

        try{


            // envia o email
            let { data } = await axios.post(`/controleVersao/limparLiberados`);

            // informa
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Versões limpadas!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Versões liberadas foram limpadas.
                </div>
            </>);


        }catch(e){
            
        }

        alterarAplicandoVersao(false);
    }


    async function liberarVersoes(){

        alterarAplicandoVersao(true);

        try{


            // envia o email
            let { data } = await axios.post(`/controleVersao/liberarClientes`, {
                parametrosConsulta: ajustarParametrosConsulta()
            });

            // informa
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Versões liberadas!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Versões liberadas para todos os clientes consultados.
                </div>
            </>);

        }catch(e){

        }


        alterarAplicandoVersao(false);
    }


    async function forcarAtualizar(){

        alterarAplicandoVersao(true);

        try{

            // envia o email
            let { data } = await axios.post(`/controleVersao/forcarAtualizacao`);

            // informa
            toast(({closeToast }) => <>
                <div className="toast-header">
                    <strong className="mr-auto">Clientes atualizados!</strong>
                    <button 
                        onClick={closeToast} 
                        className="ml-2 mb-1 close btn-outline-light outline-0"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    Todos os clientes foram atualizados.
                </div>
            </>);

        }catch(e){

        }

        alterarAplicandoVersao(false);
    }




    return <>
        <Confirmar config={confirmar} alterar={alterarConfirmar}/>

        <Card className="painel-consulta border-0" >
            <Card.Header>
                <div className={'d-flex '}>
                    <Card.Title className={'flex-grow-1 d-flex align-items-center'}>
                        {/* <ClientesIcone className="icone" width={35} height={35} /> */}
                        Clientes
                    </Card.Title>
                    <div className="d-flex" style={{gap: "0.5rem"}}>
                        
                        <OverlayTrigger 
                            overlay={<Tooltip>Envia email para todos os clientes presentes na consulta atual!</Tooltip>}
                            placement='left'
                        >
                            <Button
                                variant='warning'
                                size='sm'
                                onClick={() => {
                                    history.push('/painel/empresas/emails-massa', {
                                        parametros
                                    });
                                }}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "mail-bulk"]} />
                                <span>Emails em massa</span>
                            </Button>
                        </OverlayTrigger>

                        {liberarAtualizarServidor === 'S' &&
                        <OverlayTrigger trigger="click" placement="bottom-end" 
                            overlay={
                                <Popover>
                                    <div className="p-3 d-flex flex-column" style={{gap: "1rem"}}>
                                        
                                        <OverlayTrigger 
                                            overlay={<Tooltip>Retira todas as versões liberadas para todos os clientes.</Tooltip>}
                                            placement='left'
                                        >
                                            <Button
                                                variant='success'
                                                size='sm'
                                                disabled={aplicandoVersao}
                                                onClick={() => {
                                                    
                                                    // confirma
                                                    alterarConfirmar({
                                                        aberto: true,
                                                        titulo: 'Limpar versões',
                                                        texto: `Tem certeza que deseja limpar as versões já liberadas para todos os clientes? (Vale para todos, não somente os consultados)`,
                                                        textoBotaoConfirmar: 'Limpar versões',
                                                        variantConfirmar: 'success',
                                                        variantCancelar: 'secondary',
                                                        aoConfirmar: () => {

                                                            // atualizar
                                                            limparVersoes();
                                                            alterarConfirmar(null);
                                                        },
                                                        aoCancelar: () => {
                                                            alterarConfirmar(null);
                                                        },
                                                        aoFechar: () => {
                                                            // ao fechar
                                                            alterarConfirmar(null);
                                                        }
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon className="icone" icon={["fas", "eraser"]} />
                                                <span>Limpar versões</span>
                                            </Button>
                                        </OverlayTrigger>
                                        
                                        <OverlayTrigger 
                                            overlay={<Tooltip>Libera a próxima versão para cada cliente, levando em consideração a versão atual do cliente.</Tooltip>}
                                            placement='left'
                                        >
                                            <Button
                                                variant='primary'
                                                size='sm'
                                                onClick={() => {

                                                    // confirma
                                                    alterarConfirmar({
                                                        aberto: true,
                                                        titulo: 'Liberar versões',
                                                        texto: `Tem certeza que deseja liberar as versões para os clientes consultados?`,
                                                        textoBotaoConfirmar: 'Liberar',
                                                        variantConfirmar: 'success',
                                                        variantCancelar: 'secondary',
                                                        aoConfirmar: () => {

                                                            // atualizar
                                                            liberarVersoes();
                                                            alterarConfirmar(null);
                                                        },
                                                        aoCancelar: () => {
                                                            alterarConfirmar(null);
                                                        },
                                                        aoFechar: () => {
                                                            alterarConfirmar(null);
                                                        }
                                                    })
                                                }}
                                                disabled={!(parametros.idsVersoes && parametros.idsVersoes.length > 0) || aplicandoVersao}
                                            >
                                                <FontAwesomeIcon className="icone" icon={["fas", "gavel"]} />
                                                <span>Liberar versões</span>
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger 
                                            overlay={<Tooltip>Força atualizar todos os clientes que possuem versões liberadas.</Tooltip>}
                                            placement='left'
                                        >
                                            <Button
                                                variant='warning'
                                                size='sm'
                                                disabled={aplicandoVersao}
                                                onClick={() => {
                                                    
                                                    // confirma
                                                    alterarConfirmar({
                                                        aberto: true,
                                                        titulo: 'Forçar atualizações',
                                                        texto: `Tem certeza que deseja forçar as atualizações? Irá valer para todos os clientes que possuem atualizações liberadas, não somente os consultados.`,
                                                        textoBotaoConfirmar: 'Forçar atualizações',
                                                        variantConfirmar: 'success',
                                                        variantCancelar: 'secondary',
                                                        aoConfirmar: () => {

                                                            // atualizar
                                                            forcarAtualizar();
                                                            alterarConfirmar(null);
                                                        },
                                                        aoCancelar: () => {
                                                            alterarConfirmar(null);
                                                        },
                                                        aoFechar: () => {
                                                            alterarConfirmar(null);
                                                        }
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon className="icone" icon={["fas", "exclamation-triangle"]} />
                                                <span>Forçar atualizar</span>
                                            </Button>
                                        </OverlayTrigger>

                                    </div>
                                </Popover>
                            }>
                            <Button variant="success" size="sm"
                                onClick={() => {}}
                            >
                                <FontAwesomeIcon className="icone" icon={["fas", "land-mine-on"]} />
                            </Button>
                        </OverlayTrigger>
                        }
                        
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <ParametrosConsulta 
                    parametros={parametros}
                    alterarParametros={alterarParametros}
                    mostrarFiltros={mostrarFiltros}
                    alterarMostrarFiltros={alterarMostrarFiltros}
                    alterarHabilitarIgnorarIds={alterarHabilitarIgnorarIds}
                    habilitarIgnorarIds={habilitarIgnorarIds}
                    clientesIgnorados={clientesIgnorados}
                    alterarClientesIgnorados={alterarClientesIgnorados}
                />

                <div 
                    className={'my-3'}
                >
                    <BrowserView>
                        <table className="table table-hover tabela">
                            <thead>
                                <tr>
                                    <th className={'text-center'}><ClientesIcone className="icone" width={25} height={25} style={{filter: 'grayscale(0.3)'}}/></th>
                                    <th></th>
                                    <th></th>
                                    <th>Razão social</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { retornaRegistros() }
                            </tbody>
                        </table>
                    </BrowserView>

                    <MobileView>
                        { retornaRegistros() }
                    </MobileView>
                </div>
                
                <Paginacao
                    pagina={parametros.pagina}
                    registrosPorPagina={parametros.registrosPorPagina}
                    totalRegistros={totalRegistros}
                    alterouPagina={(pagina) => {
                        alterarParametros({...parametros, pagina: pagina});
                    }}
                />

                <Legenda />

            </Card.Body>
        </Card>
    </>
}