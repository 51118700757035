import React from 'react';
import styles from './index.module.scss';

// componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Usuario from './Usuario';

export default function Horario({
    dados
}){

    return <div className={styles.horario}>

        <div
            className={styles.horarioLabel}
        >
            <div
                className={styles.labelIcone}
            >
                <span className={styles.linhaHorizontal}></span>
                <span className={styles.linha}></span>
                <div className={styles.circulo}></div>

                <div className={styles.taghoras}>
                    <FontAwesomeIcon className="icone mr-2" icon={["far", 'clock']} /> <span>{dados.hora}</span>
                </div>
            </div>
            <div
                className={styles.labelDescricao}
            >
                <div className='flex-grow-1'>
                    
                    { dados.usuarios.map(usuario => 
                        <Usuario 
                            key={usuario.dados !== null ? usuario.dados.id : null}
                            dados={usuario}
                        />
                    )}
                    
                </div>

                {/* <Toast
                    className={'mt-2 w-100'}
                >
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                        <strong className="mr-auto">Bootstrap</strong>
                        <small>11 mins ago</small>
                    </Toast.Header>
                    <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
                </Toast> */}
            </div>
        </div>

    </div>
}